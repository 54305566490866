<template>
    <b-container fluid>
        <b-row>
            <b-col cols="12">
                <h1>Suradnici</h1>
            </b-col>
            <b-col cols="6" class="print-show">
                <mara-table :item="selectedClient"></mara-table>
            </b-col>
        </b-row>
        <b-row class="my-2">
            <b-col>
                <router-link to="/associates/create">
                    <b-btn variant="primary">Novi suradnik</b-btn>
                </router-link>
            </b-col>
        </b-row>
        <b-row v-if="associates">
            <b-col>
                <b-tabs>
                    <b-tab title="Dobavljači" active>
                        <osta-table position
                                    search-key="name"
                                    :items="associates.suppliers"
                                    :fields="associatesFields"
                                    @rowClicked="goToAssociate">
                        </osta-table>
                    </b-tab>
                    <b-tab title="Kupci">
                        <osta-table position
                                    search-key="name"
                                    :items="associates.buyers"
                                    :fields="associatesFields"
                                    @rowClicked="goToAssociate">
                        </osta-table>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'associates',
        data() {
            return {
                associatesFields: [
                    {
                        key:'code',
                        label: 'Konto',
                        sortable: true
                    },
                    {
                        key: 'name',
                        label: 'Ime',
                        sortable: true
                    },
                    {
                        key: 'township.name',
                        label: 'Grad',
                        sortable: true
                    },
                    {
                        key: 'id_number',
                        label: 'ID broj',
                        sortable: false
                    },
                    {
                        key: 'pdv_number',
                        label: 'PDV broj',
                        sortable: false,
                        formatter: (value) => (value) ? value : 'Nije u sustavu'
                    }
                ]
            }
        },
        computed: {
            associates: function () {
                return this.$store.getters.associates;
            },
            selectedClient: function () {
                return this.$store.getters.selectedClient;
            }
        },
        methods: {
            goToAssociate(id) {
                this.$router.push('/associates/' + id);
            },
        }
    }
</script>
